import { GlobalContext } from "@/pages/_app";
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Input,
  Stack,
  VStack,
  useRadio,
  useRadioGroup,
  HStack,
} from "@chakra-ui/react";
import Link from "next/link";
import { SetStateAction, useContext, useState } from "react";
import {
  FaCheckCircle,
  FaApplePay,
  FaGooglePay,
  FaRegCreditCard,
} from "react-icons/fa";

interface PaymentOptionProps {
  isChecked: boolean;
  children: {
    name: string;
    icon: JSX.Element;
  };
}

const PAYMENT_METHODS = [
  {
    name: "Apple Pay",
    icon: <FaApplePay size={40} />,
  },
  {
    name: "Google Pay",
    icon: <FaGooglePay size={40} />,
  },
  {
    name: "Card",
    icon: <FaRegCreditCard size={20} />,
  },
];

export function isApplePaySupported() {
  // Check if window is defined and if ApplePaySession exists on it
  return (
    typeof window !== "undefined" &&
    "ApplePaySession" in window &&
    (window as any).ApplePaySession.canMakePayments()
  );
}

const PaymentOption = (props: PaymentOptionProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" w={"100%"}>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        backgroundColor={"gray.100"}
        _checked={{
          bg: "brand.500",
          color: "black",
          borderColor: "transparent",
        }}
        outline={"none"}
        px={5}
        py={3}
        alignItems={"center"}
        justify={"space-between"}
        h={"50px"}
      >
        <Flex alignItems={"center"}>
          {props.isChecked ? (
            <FaCheckCircle size={20} />
          ) : (
            <Box
              width={"20px"}
              height={"20px"}
              backgroundColor={"gray.300"}
              rounded={50}
            />
          )}
          <Text pl={3}>{props.children.name}</Text>
        </Flex>
        {props.children.icon}
      </Flex>
    </Box>
  );
};

export const PaymentMethod = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState("Google Pay");

  const handleChange = (data: SetStateAction<string>) => {
    setCurrentPaymentMethod(data);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "paymentOptions",
    defaultValue: "Google Pay",
    onChange: handleChange,
  });

  const group = getRootProps();

  const canMakeApplePayPayments = isApplePaySupported();

  // Filter payment methods based on Apple Pay support
  const filteredPaymentMethods = PAYMENT_METHODS.filter(
    (method) => method.name !== "Apple Pay" || canMakeApplePayPayments
  );

  const handlePayment = () => {
    console.log("HEY", globalData?.bill.tips);
  };

  return (
    <Flex direction={"column"} py={3}>
      <Text fontSize={18} fontWeight={"semibold"}>
        Payment Method
      </Text>
      <VStack {...group} my={3}>
        {filteredPaymentMethods.map((option, index) => {
          const radio = getRadioProps({ value: option.name });

          return (
            <PaymentOption key={index} {...radio} isChecked={radio.isChecked}>
              {option}
            </PaymentOption>
          );
        })}
      </VStack>
      {currentPaymentMethod === "Card" && (
        <>
          <Divider my={3} borderColor={"gray.300"} />
          <Stack backgroundColor={"gray.100"} rounded={"md"} p={5}>
            <Text fontSize={14} fontWeight={"semibold"}>
              Email
            </Text>
            <Input
              background={"white"}
              placeholder="Email address"
              focusBorderColor="brand.500"
            />
            <Text fontSize={14} fontWeight={"semibold"}>
              Enter payment details
            </Text>
            <Input
              background={"white"}
              placeholder="XXXX XXXX XXXX XXXX"
              focusBorderColor="brand.500"
            />
            <HStack>
              <Input
                background={"white"}
                placeholder="MM / YY"
                focusBorderColor="brand.500"
              />
              <Input
                background={"white"}
                placeholder="CVV"
                focusBorderColor="brand.500"
              />
            </HStack>
            <Text fontSize={14} fontWeight={"semibold"}>
              Cardholder name
            </Text>
            <Input
              background={"white"}
              placeholder="John Doe"
              focusBorderColor="brand.500"
            />
          </Stack>
        </>
      )}
      <Text fontSize={12} color={"gray.600"} mt={10}>
        By clicking on pay you agree with deskadora&apos;s{" "}
        <Link
          href=""
          style={{
            color: "black",
          }}
        >
          terms of use
        </Link>
        .
      </Text>
      <Divider my={3} borderColor={"gray.300"} />
      {currentPaymentMethod !== "Card" ? (
        <Button
          backgroundColor={"black"}
          color={"white"}
          _hover={{
            backgroundColor: "blackAlpha.700",
          }}
          onClick={() => handlePayment()}
        >
          <Flex alignItems={"center"}>
            <Text>Pay with</Text>
            <Box pt={"2px"} pl={2}>
              {currentPaymentMethod === "Google Pay" ? (
                <FaGooglePay size={40} />
              ) : (
                <FaApplePay size={40} />
              )}
            </Box>
          </Flex>
        </Button>
      ) : (
        <Button
          backgroundColor={"black"}
          color={"white"}
          onClick={() => handlePayment()}
        >
          Pay Now
        </Button>
      )}
    </Flex>
  );
};
