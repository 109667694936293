export const ITEMS_MOCK = [
  {
    name: "Coke",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Lemonade Mango",
    price: 4400,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Large Still",
    price: 4200,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Large Still",
    price: 4200,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Coke",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Coke Zero",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Sprite",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Sangria Red",
    price: 6600,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Lemonade Mango",
    price: 4400,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Coke",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Blackberry mojito",
    price: 5800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Lemonade Mango",
    price: 8800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Coke",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Wagyu Yakitori",
    price: 11600,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Edamame",
    price: 3800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Wagyu Inoki Mushroom Roll",
    price: 10500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Wagyu Maki",
    price: 13100,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Organic Chicken Strips",
    price: 15800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Crispy Burrata",
    price: 8900,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Orzo Arancini",
    price: 6500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Wagyu Tartare",
    price: 29000,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Chargrill Mix Premium Beef Cuts",
    price: 125800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Dynamite Shrimp",
    price: 9800,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Truffle Fries",
    price: 8200,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Black Cod",
    price: 24300,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Wagyu Burger Onion",
    price: 24600,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Claypot Wagyu",
    price: 17500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Orzo with Slow Cook Lamb Shoulder",
    price: 24300,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Sprite",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Fruit Platter",
    price: 11600,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Choco Mouse",
    price: 7100,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Tiramisu Milk Cake",
    price: 6600,
    currency: "AED",
    qty: 1,
    total: 0,
  },
  {
    name: "Sprite",
    price: 3500,
    currency: "AED",
    qty: 1,
    total: 0,
  },
];
