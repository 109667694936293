import { useState, useContext, useEffect } from "react";
import { Input, Flex, Button, Text, HStack, Divider } from "@chakra-ui/react";
import { IoMdCloseCircle } from "react-icons/io";
import { GlobalContext } from "@/pages/_app";

export const Tips = ({ isPaid }: { isPaid: boolean }) => {
  const [currentAmoutToPay, setCurrentAmountToPay] = useState(0);
  const [value, setValue] = useState<number>(0);
  const [activeButton, setActiveButton] = useState<number | null>(1);
  const [customTip, setCustomTip] = useState<number | string>("");
  const [globalData, setGlobalData] = useContext(GlobalContext);
  const [percentage, setPercentage] = useState(globalData?.bill.tipsOptions[1]);

  useEffect(() => {
    if (globalData?.bill.currentShare !== undefined) {
      setCurrentAmountToPay(globalData?.bill.currentShare);
    } else if (globalData?.bill.totalBill !== undefined) {
      setCurrentAmountToPay(globalData?.bill.totalBill);
    }
  }, [globalData]);

  useEffect(() => {
    if (currentAmoutToPay && percentage !== undefined) {
      setValue(Math.round(currentAmoutToPay * percentage));
      setCustomTip("");
      setActiveButton(1);
      setGlobalData((prevGlobalData: any) => ({
        ...prevGlobalData,
        bill: {
          ...prevGlobalData.bill,
          tips: Math.round(currentAmoutToPay * percentage),
        },
      }));
    }
  }, [currentAmoutToPay]);

  const handleButtonClick = (percentage: number, buttonIndex: number) => {
    setActiveButton(activeButton === buttonIndex ? -1 : buttonIndex);
    setCustomTip("");
    setPercentage(percentage);
    if (globalData?.bill.totalBill !== undefined) {
      setValue(
        activeButton === buttonIndex ? 0 : currentAmoutToPay * percentage
      );

      setGlobalData((prevGlobalData: any) => ({
        ...prevGlobalData,
        bill: {
          ...prevGlobalData.bill,
          tips: Math.round(currentAmoutToPay * percentage),
        },
      }));
    }
  };

  const handleCustomTipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomTip(e.target.value);
    setValue(Number(e.target.value) * 100);

    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      bill: {
        ...prevGlobalData.bill,
        tips: Number(e.target.value) * 100,
      },
    }));
  };

  const handleCustomTipBlur = () => {
    if (customTip === "") {
      setCustomTip("");
      setValue(0);
    }
  };

  let amountToPay = isPaid
    ? value
    : value + (globalData?.bill.currentShare ?? 0);

  return (
    <Flex direction={"column"} py={3}>
      <Text fontSize={18} fontWeight={"semibold"}>
        {value === 0 && customTip === ""
          ? "Do you want to include a tip?"
          : "Your tip matters!"}
      </Text>
      <HStack mt={5} fontSize={14}>
        <Text>Tip amount:</Text>
        <Text fontWeight={"semibold"}>
          {globalData?.bill.currency} {(value / 100).toFixed(2)}
        </Text>
      </HStack>
      <HStack my={5}>
        {globalData?.bill.tipsOptions.map((percentage, index) => (
          <Button
            key={index}
            leftIcon={activeButton === index ? <IoMdCloseCircle /> : undefined}
            width={100}
            backgroundColor={activeButton === index ? "brand.500" : "gray.200"}
            color={activeButton === index ? "black" : "black"}
            _hover={{
              backgroundColor:
                activeButton === index ? "brand.500" : "gray.200",
            }}
            onClick={() => handleButtonClick(percentage, index)}
          >
            {`${percentage * 100}%`}
          </Button>
        ))}
      </HStack>
      <Input
        placeholder="Pay custom tip"
        focusBorderColor="brand.500"
        borderStyle="dashed"
        type="number"
        value={customTip}
        onFocus={() => {
          setActiveButton(-1);
          setCustomTip("");
          setValue(0);
        }}
        onBlur={handleCustomTipBlur}
        onChange={handleCustomTipChange}
      />
      <Divider mt={5} borderColor={"gray.300"} />
      <Flex direction={"column"} mt={5}>
        {value > 0 && (
          <HStack justify={"space-between"} color={"gray.600"}>
            <Text fontSize={14}>Tip amount</Text>
            <Text fontSize={14}>
              {globalData?.bill.currency} {(value / 100).toFixed(2)}
            </Text>
          </HStack>
        )}
        <HStack justify={"space-between"}>
          <Text fontSize={18}>You pay</Text>
          <Text fontSize={16}>
            {globalData?.bill.currency} {(amountToPay / 100).toFixed(2)}
          </Text>
        </HStack>
        <Text fontSize={12} color={"gray.600"}>
          Inclusive of all taxes and charges
        </Text>
      </Flex>
    </Flex>
  );
};
