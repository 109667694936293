import { GlobalContext } from "@/pages/_app";
import { Flex, Button, Text, HStack } from "@chakra-ui/react";
import { useContext } from "react";

export const YourShare = () => {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  return (
    <Flex direction={"column"} py={3}>
      <Flex justify={"space-between"}>
        <Text fontSize={14} fontWeight={"semibold"}>
          Payable amount
        </Text>
        <HStack fontWeight={"semibold"}>
          <Text>
            {globalData?.bill.totalBill !== undefined
              ? `${globalData?.bill.currency} ${(
                  globalData?.bill.totalBill / 100
                ).toFixed(2)}`
              : "N/A"}
          </Text>
        </HStack>
      </Flex>
      {globalData?.bill.currentShare !== undefined &&
        globalData.bill.currentShare > 0 && (
          <Flex justify={"space-between"}>
            <Text fontSize={18} fontWeight={"semibold"}>
              Your Share
            </Text>
            <HStack fontWeight={"semibold"}>
              <Text>{globalData?.bill.currency}</Text>
              <Text>{(globalData?.bill.currentShare / 100).toFixed(2)}</Text>
            </HStack>
          </Flex>
        )}
      <Text fontSize={12} color={"gray.600"}>
        Inclusive of all taxes and charges
      </Text>
      <HStack justify={"space-between"} pt={3}>
        <Button
          width={"100%"}
          backgroundColor={"black"}
          color={"white"}
          _hover={{
            backgroundColor: "blackAlpha.700",
          }}
          onClick={() =>
            setGlobalData((prevGlobalData: any | null) => ({
              ...prevGlobalData,
              splitBillDrawer: { isOpen: true },
            }))
          }
        >
          Split Bill
        </Button>
        <Button
          width={"100%"}
          backgroundColor={"brand.500"}
          color={"black"}
          _hover={{
            backgroundColor: "brand.50",
          }}
          onClick={() => alert("Pay Fully")}
        >
          Pay Fully
        </Button>
      </HStack>
    </Flex>
  );
};
